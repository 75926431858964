<template>
    <div class="app-content-wrapper apps-wrapper shadow-lg">
        <div :class="{open: isBlock}" class="app-overlay" @click="overlayApp" />
        <div :class="{open: isOpen}" class="app-sidebar">
            <div class="app-sidebar-header white  py-5">
                <h4>Attributes</h4>
            </div>
            <vue-perfect-scrollbar
                :settings="{suppressScrollX: true, wheelPropagation: false}"
                class="h-100 rtl-ps-none ps scroll"
                style="height:100%"
            >
                <div class="app-sidebar-body mt-6">
                    <v-list flat>
                        <v-list-item-group>
                            <v-list-item class="px-10 menutab"
                                :class="(selectedTab === 'common') ? 'primary' : '' "
                                @click="selectTab({code: 'common'})"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium">
                                        Common Attributes
                                    </v-list-item-title>
                                </v-list-item-content>                                
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-list flat>
                        <v-subheader
                            class="text--disabled font-weight-medium text-uppercase"
                        >
                            Per Network
                        </v-subheader>                        
                        <v-list-item-group>

                            
<!-- 
                            <v-list-item
                                v-for="(item, i) in networks"
                                :key="i"
                                class="px-10 menutab"                                                                
                                :class="(item.code === selectedTab.replace('network:#:','')) ? 'primary' : '' "
                                @click="selectTab(item, 'network')"
                            >
                            <v-tooltip top open-on-hover>
                                <template v-slot:activator="{on, attrs}">
                                    <v-list-item-icon v-if="item.type === 'physical' " class="mr-3">
                                        <v-icon>mdi-devices</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-icon v-else-if="item.type === 'web' " class="mr-3">
                                        <v-icon>mdi-web</v-icon>
                                    </v-list-item-icon>                                
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="font-weight-medium"
                                            v-text="item.title"
                                        />
                                    </v-list-item-content>
                                </template>
                                <span>TEST</span>
                            </v-tooltip>                                
                            </v-list-item> -->


                            <v-list-item
                                v-for="(item, i) in networks"
                                :key="i"
                                class="px-10 menutab"                                                                
                                :class="(item.code === selectedTab.replace('network:#:','')) ? 'primary' : '' "
                                @click="selectTab(item, 'network')"
                            >
                                <v-list-item-icon v-if="item.type === 'physical' " class="mr-3">
                                    <v-icon>mdi-devices</v-icon>
                                </v-list-item-icon>
                                <v-list-item-icon v-else-if="item.type === 'web' " class="mr-3">
                                    <v-icon>mdi-web</v-icon>
                                </v-list-item-icon>                                
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="font-weight-medium"
                                        style="font-size: 0.9em"
                                        v-text="item.title"
                                    />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-list flat>
                        <v-subheader
                            class="text--disabled font-weight-medium text-uppercase"
                        >
                            Per Device Type
                        </v-subheader>
                        <v-list-item-group>
                            <v-list-item
                                v-for="(item, i) in devicetypes"
                                :key="i"
                                class="px-10 menutab"
                                :class="(item.code === selectedTab.replace('devicetype:#:','')) ? 'primary' : '' "
                                @click="selectTab(item, 'devicetype')"
                            >
                                <!-- <v-list-item-icon class="mr-3">
                                    <v-icon
                                        v-text="item.icon"
                                        :color="item.color"
                                    />
                                </v-list-item-icon> -->
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="font-weight-medium"
                                        style="font-size: 0.9em"
                                        v-text="item.title"
                                    />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
            </vue-perfect-scrollbar>
        </div>
        <div class="app-content relative">
            <div class="app-header white">
                <div
                    class="d-flex justify-space-between flex-wrap align-baseline"
                >                    
                    <div class="">
                        <!-- app-icon class -->
                        <div
                            class="nested-sidebar-toggle d-sm-block d-md-none"
                            @click="appToggle"
                        >
                            <v-icon color="dark">
                                mdi-menu-open
                            </v-icon>
                        </div>
                        
                        <v-text-field
                            hide-details="auto"
                            class="sm:hidden md:flex ma-0 pa-0"
                            label="Search"
                            v-model="quickSearch"
                            outlined
                            dense
                            prepend-inner-icon="mdi-magnify"                            
                        ></v-text-field>                        
                    </div>
                    <div>
                    <v-dialog v-model="dialog" persistent max-width="900px" eager>
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                                class="rounded-lg py-5"
                                tile
                                block
                                color="primary"
                                v-bind="attrs"
                                v-on="on"  
                                @click="createAttribute"                                                              
                            >
                                <v-icon left>
                                    mdi-plus
                                </v-icon>
                                New Attribute
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span v-if="this.newAttribute.previouscode" class="headline">Edit Attribute "{{this.newAttribute.previouscode}}"</span>
                                <span v-else class="headline">Add a New Attribute</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-form  v-model="editFormValid" ref="editForm">

                                    <v-row>
                                        <v-col cols="12" sm="8">
                                            <v-row>
                                                <h5>Basic</h5>
                                            </v-row>
                                            <v-row class="py-0">
                                                <v-col cols="12" sm="6" class="py-0">
                                                    <v-text-field
                                                        v-model="newAttribute.name"
                                                        label="Name"
                                                        :rules="requiredFieldRules"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" class="py-0">
                                                    <v-autocomplete
                                                        v-model="newAttribute.type"
                                                        :items="[{value: 'text', text: 'Free Text'},
                                                            {value: 'tagsingle', text: 'Tag (Single)'},
                                                            {value: 'tagmultiple', text: 'Tags (Multiple)'},
                                                            {value: 'number', text: 'Number'},
                                                            {value: 'date', text: 'Date'},
                                                        ]"
                                                        label="Type"  
                                                        :rules="requiredFieldRules"                                              
                                                    ></v-autocomplete>
                                                </v-col>
                                                <!-- <v-col cols="12" sm="6">
                                                    <v-autocomplete
                                                        v-model="newTodo.items"
                                                        :items="[
                                                            'Frontend',
                                                            'Backend',
                                                            'Designer'
                                                        ]"
                                                        label="Interests"
                                                        multiple
                                                    ></v-autocomplete>
                                                </v-col> -->
                                                <v-col cols="12" class="py-0">
                                                    <v-text-field
                                                        v-model="newAttribute.description"
                                                        label="Description"
                                                        required
                                                    ></v-text-field>
                                                </v-col>

                                                
                                                <!-- <v-col cols="12" sm="6"> -->
                                                    <!-- <v-select
                            v-model="newTodo.personAvatar"
                            :items="personAvatar"
                            label="Standard"
                            ></v-select> -->

                                                    <!-- <v-select
                                                        :items="personsInfo"
                                                        v-model="newTodo.personInfo"
                                                        item-text="name"
                                                        item-value="image"
                                                        single-line
                                                        auto
                                                        label="Seleccionar Grupo"
                                                    >
                                                        <template v-slot:selection="{item}">
                                                            <div>
                                                                <v-avatar
                                                                    size="30"
                                                                    class="mr-2"
                                                                >
                                                                    <img
                                                                        :src="item.image"
                                                                        alt=""
                                                                    />
                                                                </v-avatar>
                                                                <span class="ma-0 pa-0">{{
                                                                    item.name
                                                                }}</span>
                                                            </div>
                                                        </template>
                                                        <template v-slot:item="{item}">
                                                            <div>
                                                                <v-avatar
                                                                    size="30"
                                                                    class="mr-2"
                                                                >
                                                                    <img
                                                                        :src="item.image"
                                                                        alt=""
                                                                    />
                                                                </v-avatar>
                                                                <span class="ma-0 pa-0">{{
                                                                    item.name
                                                                }}</span>
                                                            </div>
                                                        </template>
                                                    </v-select> -->
                                                <!-- </v-col> -->
                                            </v-row>
                                            <v-row>
                                                <h5>Grouping</h5>
                                            </v-row>
                                            <v-row>                                                
                                                <v-col cols="12" sm="6">                                            
                                                    <v-autocomplete
                                                        v-model="newAttribute.hierarchy"
                                                        :items="hierarchiesList"
                                                        label="Category"    
                                                        clearable                                            
                                                    ></v-autocomplete>                                        
                                                </v-col>
                                                <v-col cols="12" sm="6">                                            
                                                    <v-autocomplete
                                                        v-model="newAttribute.parent"
                                                        :items="hierarchyParentList"
                                                        label="Parent Attribute"     
                                                        clearable                                           
                                                    ></v-autocomplete>                                        
                                                </v-col>
                                            </v-row>                                                                        
                                            <v-row>
                                                <h5>Ingestion</h5>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="6">                                            
                                                    <v-autocomplete
                                                        v-model="newAttribute.ingestionconnector"
                                                        :items="ingestionConnectorsList"
                                                        label="Connector"   
                                                        clearable                                             
                                                    ></v-autocomplete>                                        
                                                </v-col>
                                                <v-col cols="12" sm="6">                                            
                                                    <v-text-field
                                                        v-model="newAttribute.ingestionsource"
                                                        label="Source Binding"
                                                        required
                                                    ></v-text-field>                                        
                                                </v-col>
                                            </v-row>







                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-row >
                                                <h5>Values</h5>
                                            </v-row>
                                            <v-row class="py-0">
                                                <v-col cols="12" sm="12" class="py-0">                                            
                                                    <v-text-field
                                                        v-model="newAttribute.defaultvalue"
                                                        label="Default Value"                                                        
                                                    ></v-text-field>                                        
                                                </v-col>
                                            </v-row>                                            
                                            <v-row class="py-0">
                                                <v-col cols="12" sm="12" class="py-0">                                            
                                                    <v-autocomplete
                                                        v-model="newAttribute.valuelisttype"
                                                        :items="valueListTypeOptions"
                                                        label="Allowed Values List"   
                                                        clearable                                             
                                                    ></v-autocomplete>                                        
                                                </v-col>                                                
                                            </v-row>
                                            <v-row class="py-0" v-if="newAttribute.valuelisttype === 'predefined'">
                                                <v-col cols="12" sm="12" class="py-0">                                            
                                                    <v-text-field
                                                        v-model="newAttribute.valuetoadd"
                                                        label="Value To Add..."                                                            
                                                        :append-outer-icon="'mdi-plus-box'"
                                                        @click:append-outer="addToValueList()"
                                                        clearable
                                                    ></v-text-field>                                        
                                                </v-col>
                                            </v-row>  
                                            <v-row class="py-0" v-if="newAttribute.valuelisttype === 'predefined'">
                                                <v-col cols="12" sm="12" class="py-0">                                                    
                                                    <v-simple-table dense class="py-0">
                                                        <template v-slot:default>
                                                        <thead>
                                                            <tr>
                                                            <th class="text-left">List of Values</th>                              
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <v-container style="position: relative; overflow: hidden;">
                                                                    
                                                                    <vue-perfect-scrollbar
                                                                        :settings="{suppressScrollX: true, wheelPropagation: true}"
                                                                        
                                                                        style="height:200px;"
                                                                    >
                                                                    <v-chip v-for="item in newAttribute.valuelist" :key="item" 
                                                                        class="ma-2" 
                                                                        color="info"                                                       
                                                                        close
                                                                        @click="select"
                                                                        @click:close="removeValueList(item)"
                                                                    >                                                                    
                                                                        {{item}}
                                                                    </v-chip>                                                                    
                                                                    </vue-perfect-scrollbar>
                                                                    </v-container>
                                                                </td>
                                                            </tr>
                                                            <!-- <tr v-for="item in newAttribute.valuelist" :key="item">
                                                            <td>{{ item }}</td>                                                            
                                                            </tr> -->
                                                        </tbody>
                                                        </template>
                                                    </v-simple-table>                                                                                                        
                                                    
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>



                                    
                                    </v-form>
                                    <v-alert dense outlined type="error" v-if="errorMsg">
                                        {{ errorMsg }}
                                    </v-alert>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="dialog = false"
                                    >Close</v-btn
                                >
                                <v-btn color="primary" @click="saveAttribute">Save</v-btn>
                                <v-snackbar v-model="snackbar" :timeout="timeout">
                                    {{ text }}

                                    <template v-slot:action="{attrs}">
                                        <v-btn
                                            color="blue"
                                            text
                                            v-bind="attrs"
                                            @click="snackbar = false"
                                        >
                                            Close
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    </div>
                    <!-- <div>
                        <v-btn icon color="primary">
                            <v-icon>mdi-account-check</v-icon>
                        </v-btn>
                        <v-btn icon color="primary">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                        <v-btn icon color="primary">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </div> -->
                </div>
            </div>
            <vue-perfect-scrollbar
                :settings="{suppressScrollX: true, wheelPropagation: false}"
                class="h-100 rtl-ps-none ps scroll app-sidebar-scroll"
                style="height:100%"
            >
                <div class="app-body mt-11 px-8 mb-10">
                    <!-- FOR DEMO  -->
                    <base-card>
                        <!-- <v-card-title>Dense</v-card-title> -->
                        <v-card-text>
                        <v-tabs>
                            <v-tab>Active</v-tab>
                            <v-tab disabled >Archived</v-tab>                            
                        </v-tabs>
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">Name</th>
                                <th class="text-left">Type</th>
                                <th class="text-left">Description</th>                                
                                <th class="text-left">Category</th>
                                <th class="text-left">Parent Attr.</th>
                                <th class="text-left">Default Value</th>
                                <th class="text-left">Ingestion Source</th>                                
                                <th class="text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in filteredAttributesList" :key="item.name">
                                <td>{{ item.name }}</td>
                                <td>{{ item.type }}</td>
                                <td>{{ item.description }}</td>
                                <td>{{ item.hierarchy }}</td>
                                <td>{{ item.parent }}</td>
                                <td>{{ item.defaultvalue }}</td>
                                <td>{{ (item.ingestionconnector ? item.ingestionconnector : '') + ((item.ingestionconnector && item.ingestionsource) ? ':': '') + (item.ingestionsource ? item.ingestionsource : '')}}</td>
                                <td>
                                    <v-btn
                                        color="success"
                                        dark                                        
                                        icon         
                                        @click="editAttribute(item)"                               
                                    >
                                        <v-icon>mdi-pencil-box-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="danger"
                                        dark                                        
                                        icon  
                                        @click="confirmDeleteAttribute(item.name)"
                                    >
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                        </v-card-text>
                    </base-card>
                    <!-- <div
                        v-for="(todo, index) in todos"
                        :key="index"
                        class="eg-todo-item d-flex justify-space-between flex-wrap align-center mb-5 white pa-4 rounded"
                    >
                        <div class="d-flex align-center">
                            <v-checkbox v-model="todo.select"></v-checkbox>
                            <h6
                                class="ma-0"
                                :class="{'line-through': todo.select}"
                                @click="editTodo(todo)"
                                @click.stop="drawer = !drawer"
                            >
                                {{ todo.title }}
                            </h6>
                        </div>
                        <div class="">
                            <div>
                                <v-chip
                                    v-for="(chip, index2) in todo.tags"
                                    :key="index2"
                                    class="mr-2"
                                    color="primary"
                                    small
                                    @click.stop="drawer = !drawer"
                                >
                                    {{ chip }}
                                </v-chip>
                                <v-avatar
                                    class="ml-2"
                                    size="36"
                                    @click.stop="drawer = !drawer"
                                >
                                    <img :src="todo.avatar" alt="" />
                                </v-avatar>
                                <v-btn icon class="ml-2">
                                    <v-icon>mdi-star</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    class="ml-2"
                                    @click="removeTodo(todo)"
                                >
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </div> -->
                </div>
            </vue-perfect-scrollbar>
        </div>
        <!-- drawer  -->
        <div>
            <v-navigation-drawer v-model="drawer" absolute temporary right>
                <div class="pa-3">
                    <v-text-field
                        v-model="newEditTodo.title"
                        label="task name..."
                    ></v-text-field>
                    <v-text-field
                        v-model="newEditTodo.desc"
                        label="descriptions..."
                    ></v-text-field>

                    <v-select
                        :items="personsInfo"
                        v-model="newEditTodo.personInfo"
                        item-text="name"
                        item-value="image"
                        single-line
                        auto
                        label="Seleccionar Grupo"
                    >
                        <template v-slot:selection="{item}">
                            <div>
                                <v-avatar size="30" class="mr-2">
                                    <img :src="item.image" alt="" />
                                </v-avatar>
                                <span class="ma-0 pa-0">{{ item.name }}</span>
                            </div>
                        </template>
                        <template v-slot:item="{item}">
                            <div>
                                <v-avatar size="30" class="mr-2">
                                    <img :src="item.image" alt="" />
                                </v-avatar>
                                <span class="ma-0 pa-0">{{ item.name }}</span>
                            </div>
                        </template>
                    </v-select>

                    <v-btn color="primary" @click="doneEditTodo(newEditTodo)"
                        >Update</v-btn
                    >
                </div>
            </v-navigation-drawer>
        </div>
        <v-dialog v-model="deleteDialog" width="500">
            <v-card class="">
                <v-card-title>
                    <span class="headline">Delete</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        Are you sure you want to delete this record?
                    </v-container>
                    <!-- <small>*indicates required field</small> -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteDialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="deleteAttribute()"
                        >Delete</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>    
</template>

<script>
import axios from 'axios'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import api from "src/api/index";
import { fieldNotEmpty, fieldMinLength } from "src/core/vuetify-validators.js";
export default {
    name: 'Attributes',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Attributes'
    },
    components: {
        VuePerfectScrollbar
    },
    async created() {
        // axios.get('/api/todo/').then((response) => {
        //     this.todos = response.data
        //     //  console.log(this.todos);
        // })
        const rsp = await api.attributes.getManagementData(); 
        const allData = rsp.data;
        this.managementData = allData;
        //alert(JSON.stringify(allData, null, 2));
    },
    async mounted() {
        // const rsp = await api.attributes.getManagementData();
        // const allData = rsp.data;
        // this.managementData = allData;
    },
    data: () => ({
        managementData: {},
        attributesList: [],
        newAttribute: {
            previouscode: null,
            name: null,
            type: null,
            description: null,
            parent: null,
            hierarchy: null,
            ingestionconnector: null,
            ingestionsource: null,
            defaultvalue: null,
            valuelisttype: 'free',
            valuetoadd: null,
            valuelist: []
        },
        networks: [], //[{code: "stores", title: "Kotsovolos Stores", type: "physical"}, {code: "website", title: "Kotsovolos Website", type: "web"}, {code: "coffeeisland", title: "Coffee Island", type: "physical"}],
        devicetypes: [], //[{code: "mobile", title: "Mobile"}, {code: "tv", title: "TV"}, {code: "display", title: "Display"},],
        selectedTab: 'common',
        selectedActiveTab: 'active',

        hierarchyParentList: [],
        hierarchiesList: [{value: "positional", text:"Positional/Geographical"}, {value: "productcategory", text: "Product Categorization"}],
        ingestionConnectorsList: ["Yodiwo Signage","Yodiwo Range", "Kots Web Zones", "Dixons - Product Characteristics"],
        valueListTypeOptions: [{value: 'free', text: "Any Value/No Restriction"}, {value: 'predefined', text: "Predefined List"}],

        requiredFieldRules: [fieldNotEmpty],
        editFormValid: true,
        errorMsg: '',
        dialog: false,
        deleteDialog: false,
        attributeNameToDelete: '',
        quickSearch: '',



        todos: [],
        selected: [''],
        //////-----/////
        snackbar: false,
        isOpen: false,
        isBlock: false,
        checkbox: false,
        
        isLineThrough: false,
        text: 'Task Name Must Be Required...',
        timeout: 2000,
        // item: 1,
        // itemsTwo: 0,
        drawer: null,
        newEditTodo: '',
        newTodo: {},

        personsInfo: [
            {
                image: require('@/assets/images/avatars/001-man.svg'),
                name: 'Timothy Clarkson'
            },
            {
                image: require('@/assets/images/avatars/002-woman.svg'),
                name: 'Medusa'
            }
        ],
        personAvatar: [
            require('@/assets/images/avatars/001-man.svg'),
            require('@/assets/images/avatars/002-woman.svg')
        ],

        items: [
            {text: 'Favorite', icon: 'mdi-star-outline'},
            {text: 'Important', icon: 'mdi-alert-decagram-outline'},
            {text: 'Completed', icon: 'mdi-account-check-outline'},
            {text: 'Delete Files', icon: 'mdi-delete-outline'}
        ],
        itemsTwo: [
            {
                text: 'Frontend',
                icon: 'mdi-checkbox-blank-circle-outline mdi-18px',
                color: 'primary'
            },
            {
                text: 'Backend',
                icon: 'mdi-checkbox-blank-circle-outline mdi-18px',
                color: 'orange'
            },
            {
                text: 'Doc',
                icon: 'mdi-checkbox-blank-circle-outline mdi-18px',
                color: 'success'
            },
            {
                text: 'Bug',
                icon: 'mdi-checkbox-blank-circle-outline mdi-18px',
                color: 'danger'
            }
        ],
        chips: [
            'Programming',
            'Playing video games',
            'Watching movies',
            'Sleeping'
        ],
        itemsChip: ['Streaming', 'Eating']
    }),
    watch: {

        managementData() {            
            this.refreshUI();
        },
        selectedTab() {
            this.refreshUI();
        },
        dialog() {
            this.$refs.editForm.resetValidation();
        }
    },
    methods: {
        selectTab: function(item, type) {
            this.selectedTab = (type ? type + ':#:' : '') + item.code;
        },
        refreshUI: function() {
            //set main list/grid items (attributes)
            if(this.selectedTab === 'common') {
                this.attributesList = this.managementData.common.map(d=> {return {...d, previouscode: d.name}});
            }
            else if (this.selectedTab.startsWith('network:#:')) {
                const term = this.selectedTab.replace('network:#:', '');
                const record = this.managementData.networks.find(r=>r.code === term)
                if (record && record.adp_attrs && record.adp_attrs.data) {
                    this.attributesList = record.adp_attrs.data.map(d=> {return {...d, previouscode: d.name}});
                }                     
                else {
                    this.attributesList = [];
                }
            }
            else if (this.selectedTab.startsWith('devicetype:#:')) {
                const term = this.selectedTab.replace('devicetype:#:', '');
                const record = this.managementData.devicetypes.find(r=>r.code === term)
                if (record && record.adp_attrs && record.adp_attrs.data) {
                    this.attributesList = record.adp_attrs.data.map(d=> {return {...d, previouscode: d.name}});
                }
                else {
                    this.attributesList = [];
                }                
            }
            //set eligible parents from attributes list
            this.hierarchyParentList = this.attributesList.map(a=> {return {value: a.name, text: '[' + a.name + ']' + ' - ' + a.description}});

            this.networks = this.managementData.networks;
            this.devicetypes = this.managementData.devicetypes;
        },        
        removeValueList: function(item) {
            this.newAttribute.valuelist = this.newAttribute.valuelist.filter(i=>i!=item);            
        },
        addToValueList: function() {
            if(!this.newAttribute.valuelist) {
                this.newAttribute.valuelist = [];
            }
            this.newAttribute.valuelist.push(this.newAttribute.valuetoadd);
            this.newAttribute.valuetoadd='';
        },
        validateIfAttributeNameAlreadyExists: function(attrToUpdate) {
            if(attrToUpdate.previouscode == attrToUpdate.name || !attrToUpdate.name) {
                //we are not changing the name so no need to check for conflict
                return true;
            }
            const existingAttribute = this.attributesList.find(a=>a.name == attrToUpdate.name);
            if(existingAttribute) {
                return false;
            }
            return true;
        },
        saveAttribute: async function() {
            this.errorMsg = '';
            //validate form
            this.$refs.editForm.validate();            
            //if not valid show messages
            if(!this.editFormValid) {
                return;
            }                        
            const nameAlreadyExists = !this.validateIfAttributeNameAlreadyExists(this.newAttribute);
            if (nameAlreadyExists) {
                this.errorMsg = 'Name "' + this.newAttribute.name + '" already belongs to another attribute.\nPlease use a different Name.';
                return;
            }            
            try{
                //if valid send request
                if(this.selectedTab === "common") {
                    const rsp = await api.attributes.createCommonAttribute(this.newAttribute);                       
                    this.managementData.common = rsp.data.data.data;                         
                }
                else if (this.selectedTab.startsWith("network:#:")) {
                    const rsp = await api.attributes.createNetworkAttribute(this.selectedTab.replace("network:#:", ''), this.newAttribute);                                                                                
                    for(let i=0; i<this.managementData.networks.length; i++) {
                        if(this.managementData.networks[i].code == this.selectedTab.replace("network:#:", '')) {
                            this.managementData.networks[i].adp_attrs = rsp.data.adp_attrs; //{..., code: "network:#:" + rsp.data.code};
                        }
                    }                    
                }
                else if (this.selectedTab.startsWith("devicetype:#:")) {
                    const rsp = await api.attributes.createDeviceTypeAttribute(this.selectedTab.replace("devicetype:#:", ''), this.newAttribute);                                        
                    for(let i=0; i<this.managementData.devicetypes.length; i++) {
                        if(this.managementData.devicetypes[i].code == this.selectedTab.replace("devicetype:#:", '')) {
                            this.managementData.devicetypes[i].adp_attrs = rsp.data.adp_attrs; //{...rsp.data, code: "devicetype:#:" + rsp.data.code};
                        }
                    }                    
                }
                this.dialog = false;
                this.refreshUI();
            }
            catch(err) {
                this.errorMsg = err.response.data.message;
            }            
            //if request unsuccessfull/invalid show messages
            //if request successfull close dialog & add to localmodel OR reload
        },

        createAttribute: function() {
            this.newAttribute = {
                previouscode: null,
                name: null,
                type: null,
                description: null,
                parent: null,
                hierarchy: null,
                ingestionconnector: null,
                ingestionsource: null,
                defaultvalue: null,
                valuelisttype: 'free',
                valuetoadd: null,
                valuelist: []
            }            
            //alert("BEEN HERE");
            //this.$refs.editForm.resetValidation();
        },

        editAttribute: function(attr) {
            this.newAttribute = {
                previouscode: attr.name,
                name: attr.name,
                type: attr.type,
                description: attr.description,
                parent: attr.parent,
                hierarchy: attr.hierarchy,
                ingestionconnector: attr.ingestionconnector,
                ingestionsource: attr.ingestionsource,
                defaultvalue: attr.defaultvalue,
                valuelisttype: attr.valuelisttype,
                valuetoadd: null,
                valuelist: attr.valuelist
            };
            //this.$refs.editForm.resetValidation();
            this.dialog = true;
        },

        confirmDeleteAttribute: function(attrName) {
            this.attributeNameToDelete = attrName;
            this.deleteDialog = true;                        
        },

        deleteAttribute: async function() {      
            this.deleteDialog = false;      
            const attrName = this.attributeNameToDelete;
            if(this.selectedTab === "common") {
                const rsp = await api.attributes.deleteCommonAttribute(attrName);
                this.managementData.common = rsp.data.data.data;                     
            }
            else if (this.selectedTab.startsWith("network:#:")) {
                const rsp = await api.attributes.deleteNetworkAttribute(this.selectedTab.replace("network:#:", ''),attrName);
                for(let i=0; i<this.managementData.networks.length; i++) {
                    if(this.managementData.networks[i].code == this.selectedTab.replace("network:#:", '')) {
                        this.managementData.networks[i].adp_attrs = rsp.data.adp_attrs; //{..., code: "network:#:" + rsp.data.code};
                    }
                }
            }
            else if (this.selectedTab.startsWith("devicetype:#:")) {
                const rsp = await api.attributes.deleteDeviceTypeAttribute(this.selectedTab.replace("devicetype:#:", ''),attrName);
                for(let i=0; i<this.managementData.devicetypes.length; i++) {
                    if(this.managementData.devicetypes[i].code == this.selectedTab.replace("devicetype:#:", '')) {
                        this.managementData.devicetypes[i].adp_attrs = rsp.data.adp_attrs; //{...rsp.data, code: "devicetype:#:" + rsp.data.code};
                    }
                }
            }
            this.dialog = false;
            this.refreshUI();
        },

        editFormOpened: function() {
            this.$refs.editForm.resetValidation();
        },







        addTodo: function() {
            axios
                .post('/api/todo/add', {data: this.newTodo})

                .then((response) => {
                    this.todos.push({
                        title: this.newTodo.msg,
                        desc: this.newTodo.taskName,
                        tags: this.newTodo.items,
                        avatar: this.newTodo.personInfo
                    })
                })
            this.dialog = false
        },
        // checkTodo: function(todo){
        //   axios.patch(`/api/todos/${todo.id}`, {data: todo})
        // },
        removeTodo: function(todo) {
            // this.todos.splice(index, 1)
            let {id} = todo

            axios.post('/api/todo/delete', {id}).then((response) => {
                this.todos = this.todos.filter((todo) => todo.id !== id)
            })
        },
        editTodo: function(todo) {
            this.newEditTodo = todo
            // console.log(todo)
        },
        doneEditTodo: function(todo) {
            // console.log(todo);
            axios.post('/api/todo/update', {todo}).then((response) => {
                this.todos = this.todos.map((todo) => {
                    if (todo.id == response.data.id) {
                        console.log(response.data, 'frontend')
                        this.drawer = !this.drawer
                        return response.data
                    } else return todo
                })
            })
        },
        textDecoration: function(todo) {
            todo.isCompleted = !todo.isCompleted
        },
        appToggle: function() {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        },
        overlayApp: function() {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        }
    },
    computed: {
        filterSearchList() {
            return this.todos.filter((post) => {
                return post.title
                    ?.toLowerCase()
                    .includes(this.todoSearch?.toLowerCase())
            })
        },

        filteredAttributesList(){            
            const term = this.quickSearch;
            return this.attributesList.filter(a=>  
                        a.name?.toLowerCase().includes(term?.toLowerCase()) 
                        || a.description?.toLowerCase().includes(term?.toLowerCase())
            );            
        }
    }
}
</script>
<style lang="scss" scoped>

.menutab.primary {
    .v-icon{
        color: white;
    } 

    .v-list-item__content{
        color: white;
    }    
}











.apps-wrapper {
    .nested-sidebar-toggle {
        @media (min-width: 959px) {
            display: none;
        }
    }
}
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: calc(100% - 280px);
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>
